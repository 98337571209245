import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
//import router from "../router";
import { globals } from "../config/variables";
import { KEYUTIL, KJUR } from "jsrsasign";
import TextBausteineService from "../services/textBausteineService";

import moment from "moment";
import "moment/locale/de"; // without this line it didn't work
moment.locale("de");

Vue.use(Vuex);

function parseNum(value) {
  if (isNaN(parseFloat(value))) return 0;
  if (!isNaN(value)) return parseFloat(value);
  let result = value.replace(/\./g, "");
  result = result.replace(/,/g, ".");
  return parseFloat(result);
}
function formatNum(value) {
  let result = parseFloat(value).toFixed(2);
  result = result.toString().replace(/\./g, ",");
  result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return result;
}

export default new Vuex.Store({
  state: {
    auftragsDetails: {
      auftrag: {},
      kunde: {},
      dokumente: {}
    },
    gutachtenListe: [],
    gutachtenDetails: {},
    minderwerte: [],
    gesamtkalkulation: [],
    oauth2Object: {
      token_id: undefined,
      code: undefined,
      access_token: undefined,
      refresh_token: undefined,
      code_verifier: undefined,
      code_challenge: undefined,
      pkce_state: undefined
    },
    api: {
      domain: globals.domain,
      region: globals.region,
      endpoint_deals_list: globals.api_endpoint_deals_list,
      endpoint_edited_push: globals.api_endpoint_edited_push,
      endpoint_start_extract: globals.api_endpoint_start_extract,
      endpoint_submit_new_deal: globals.api_endpoint_submit_new_deal,
      endpoint_url_signer: globals.api_endpoint_url_signer,
      endpoint_get_signed_download_url:
        globals.api_endpoint_get_signed_download_url,
      endpoint_get_text_block_templates:
        globals.api_endpoint_get_text_block_templates,
      endpoint_put_new_text_block: globals.api_endpoint_put_new_text_block,
      endpoint_post_new_deal_state: globals.api_endpoint_post_new_deal_state,
      endpoint_get_post_generate_deal_invoice:
        globals.api_endpoint_get_post_generate_deal_invoice,
      endpoint_get_post_generate_deal_pzp:
        globals.api_endpoint_get_post_generate_deal_pzp,
      endpoint_me: globals.api_endpoint_me,
      app_client_id: globals.appClientId,
      redirect_url: globals.redirectURI,
      user_pool_id: globals.userPoolId
    },
    //username: "",
    userobject: {},
    general_app_data: {
      gutachten_details_overlay: false,
      last_active_tabroute: "/overview-qs-tech"
    },
    deal_tabs: [],
    main_context_name: "",
    text_block_templates: [],
    text_block_categories: [],
    const_antrag_typ_normal_laufzeit_in_tagen: 5,
    const_antrag_typ_express_laufzeit_in_tagen: 1
  },

  getters: {
    getAuftragsDetails: state => {
      return state.auftragsDetails;
    },
    getGutachtenListe: state => {
      return state.gutachtenListe;
    },
    getGutachtenDetails: state => {
      return state.gutachtenDetails;
    },
    getMinderwerte: state => {
      return state.minderwerte;
    },
    getGesamtkalkulation: state => {
      return state.gesamtkalkulation;
    },
    getURL: state => {
      let url = "";
      state.gutachtenListe.filter(function(element) {
        if (element.id == state.gutachtenDetails.metadata.id)
          url = element.presigned_url;
      });
      return url;
    },
    sumField: state => (minderwert_index, key) => {
      // sum data in give key (property)
      let total = state.minderwerte[minderwert_index].rows.reduce(
        (a, b) => a + parseNum(b[key] || ""),
        0
      );
      total = formatNum(total);
      console.log("total", total);
      return total;
    },
    getAuthBearer: state => {
      return state.oauth2Object.token_id === undefined
        ? localStorage.getItem("id_token") !== null
          ? localStorage.getItem("id_token")
          : undefined
        : state.oauth2Object.token_id;
    },
    getAuthAccessToken: state => {
      return state.oauth2Object.access_token === undefined
        ? localStorage.getItem("access_token") !== null
          ? localStorage.getItem("access_token")
          : undefined
        : state.oauth2Object.access_token;
    },
    getUserName: state => {
      return state.userobject.username !== undefined
        ? state.userobject.username
        : "";
      // return state.username === ""
      //     ? localStorage.getItem("username") !== null
      //         ? localStorage.getItem("username")
      //         : "  "
      //     : state.username;
    },
    getUserObject: state => {
      return state.userobject;
    },
    getMainContextName: state => {
      return state.main_context_name;
    },
    getUserPermissions: state => {
      const permissions = { tabs: [], routes: [], menu: [] };
      if (
        state.userobject.roles !== undefined &&
        state.userobject.roles.length >= 0
      ) {
        const roles = state.userobject.roles;
        if (roles.includes("admins")) {
          // tabs.push({
          //   id: "1",
          //   name: "1",
          //   step: "acceptance",
          //   text: "Akzeptanz",
          //   route: "/overview-acceptance"
          // });

          // 1. set tabs
          permissions.tabs.push({
            id: "1",
            name: "1",
            step: "qs_tech",
            text: "QS-Tech",
            route: "/overview-qs-tech"
          });
          permissions.tabs.push({
            id: "2",
            name: "2",
            step: "bearbeitung",
            text: "Bearbeitung",
            route: "/overview-bearbeitung"
          });
          permissions.tabs.push({
            id: "3",
            name: "3",
            step: "review",
            text: "Freigabe",
            route: "/overview-review"
          });
          permissions.tabs.push({
            id: "4",
            name: "4",
            step: "done",
            text: "Erledigt",
            route: "/overview-done"
          });

          // 2. set routes
          permissions.routes.push("/overview-qs-tech");
          permissions.routes.push("/overview-bearbeitung");
          permissions.routes.push("/overview-review");
          permissions.routes.push("/overview-done");
          permissions.routes.push("/account");
          permissions.routes.push("/about");
          permissions.routes.push("/neuer-auftrag");
          // 2. set menu
          permissions.menu.push({
            title: "Übersicht",
            icon: "mdi-home",
            to: "/"
          });
          permissions.menu.push({
            title: "Neu anlegen",
            icon: "mdi-file-plus",
            to: "/neuer-auftrag"
          });
          permissions.menu.push({
            title: "Hilfe",
            icon: "mdi-help",
            to: "/about"
          });
          permissions.menu.push({
            title: "Account",
            icon: "mdi-account",
            to: "/account"
          });
        } else if (roles.includes("sv-member")) {
          permissions.tabs.push({
            id: "2",
            name: "2",
            step: "bearbeitung",
            text: "Bearbeitung",
            route: "/overview-bearbeitung"
          });
          permissions.routes.push("/overview-bearbeitung");
          permissions.routes.push("/neuer-auftrag");
          permissions.routes.push("/account");
          permissions.routes.push("/about");
          permissions.menu.push({
            title: "Übersicht",
            icon: "mdi-home",
            to: "/"
          });
          permissions.menu.push({
            title: "Neu anlegen",
            icon: "mdi-file-plus",
            to: "/neuer-auftrag"
          });
          permissions.menu.push({
            title: "Hilfe",
            icon: "mdi-help",
            to: "/about"
          });
          permissions.menu.push({
            title: "Account",
            icon: "mdi-account",
            to: "/account"
          });
        } else if (roles.includes("sv-owner")) {
          permissions.tabs.push({
            id: "2",
            name: "2",
            step: "bearbeitung",
            text: "Bearbeitung",
            route: "/overview-bearbeitung"
          });
          permissions.tabs.push({
            id: "3",
            name: "3",
            step: "review",
            text: "Freigabe",
            route: "/overview-review"
          });
          permissions.tabs.push({
            id: "4",
            name: "4",
            step: "done",
            text: "Erledigt",
            route: "/overview-done"
          });
          permissions.routes.push("/overview-bearbeitung");
          permissions.routes.push("/overview-review");
          permissions.routes.push("/overview-done");
          permissions.routes.push("/neuer-auftrag");
          permissions.routes.push("/account");
          permissions.routes.push("/about");
          permissions.menu.push({
            title: "Übersicht",
            icon: "mdi-home",
            to: "/"
          });
          permissions.menu.push({
            title: "Neu anlegen",
            icon: "mdi-file-plus",
            to: "/neuer-auftrag"
          });
          permissions.menu.push({
            title: "Hilfe",
            icon: "mdi-help",
            to: "/about"
          });
          permissions.menu.push({
            title: "Account",
            icon: "mdi-account",
            to: "/account"
          });
        } else if (roles.includes("lawyer")) {
          permissions.routes.push("/neuer-auftrag");
          permissions.routes.push("/account");
          permissions.routes.push("/about");
          permissions.menu.push({
            title: "Neu anlegen",
            icon: "mdi-file-plus",
            to: "/neuer-auftrag"
          });
          permissions.menu.push({
            title: "Hilfe",
            icon: "mdi-help",
            to: "/about"
          });
          permissions.menu.push({
            title: "Account",
            icon: "mdi-account",
            to: "/account"
          });
        }
      }
      return permissions;
    },

    getGutachtenDetailOverlay: state => {
      return state.general_app_data.gutachten_details_overlay;
    },
    getLastActiveTabRoute: state => {
      return state.general_app_data.last_active_tabroute;
    },
    getAPIContext: state => {
      return state.api;
    },
    getTextBlockTemplates: state => {
      return state.text_block_templates;
    },
    getTextbausteinCategories: state => {
      return state.text_block_categories;
    },
    getConstAntragTypExpressLaufzeitInTagen: state => {
      return state.const_antrag_typ_express_laufzeit_in_tagen;
    },
    getConstAntragTypNormalLaufzeitInTagen: state => {
      return state.const_antrag_typ_normal_laufzeit_in_tagen;
    }
  },
  mutations: {
    SET_TEXTBLOCK_TEMPLATES: (state, data) => {
      state.text_block_templates = data;
    },
    SET_TEXTBLOCK_CATEGORIES: (state, data) => {
      state.text_block_categories = data;
    },
    UPDATE_AUFTRAG_DETAILS(state, data) {
      state.auftragsDetails = data;
    },
    SET_KUNDEN_DETAILS(state, data) {
      state.auftragsDetails.kunde = data;
    },
    SET_GUTACHTEN_LISTE(state, data) {
      state.gutachtenListe = data;
    },
    SET_GUTACHTEN_DETAILS(state, data) {
      state.gutachtenDetails = data;
    },
    // SET_GUTACHTEN_SUMMENZEILE: (state, data) => {
    //   const { minderwert_index, key, value } = data;
    //   state.minderwerte[minderwert_index].summary[key] = value;
    // },
    // SET_GUTACHTEN_GESAMTKALKULATION(state, gesamtKalkulation) {
    //   state.gesamtkalkulation = gesamtKalkulation;
    // },
    setOAuth2AuthorizationDetails: (state, data) => {
      state.oauth2Object = data;
      localStorage.setItem("oauth2Object", JSON.stringify(data));
    },
    // setUserName: (state, data) => {
    //   state.username = data;
    // },
    setUserObject: (state, data) => {
      state.userobject = data;
      localStorage.setItem("userobject", JSON.stringify(data));
    },
    setCurrentDealTabs: (state, data) => {
      state.deal_tabs = data;
    },
    setMainContextName: (state, data) => {
      state.main_context_name = data;
    },
    setGutachtenDetailOverlay: (state, data) => {
      state.general_app_data.gutachten_details_overlay = data;
    },
    setLastActiveTabRoute: (state, data) => {
      state.general_app_data.last_active_tabroute = data;
    }
  },

  actions: {
    async updateAuftragsDetails(context, auftragsdetails) {
      //im state speichern
      console.log("auftragsdaten im state speichern");
      context.commit("UPDATE_AUFTRAG_DETAILS", auftragsdetails);
      const url = context.state.api.endpoint_submit_new_deal;
      let tokenId = context.getters.getAuthBearer;
      console.log("auftragsdaten: body vorbereiten");
      let body = this.getters.getAuftragsDetails;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      console.log("auftragsdaten: absenden request");
      console.log("auftragsdaten-url:" + url);
      console.log("auftragsdaten-body:" + body);
      console.log("auftragsdaten-header:" + header);

      let response;
      try {
        response = await axios.post(url, body, { headers: header });
        console.log("auftragsdaten: abgesendet");
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          //Wenn Hersteller == null dann Wert einsetzen
          //context.commit("SET_GUTACHTEN_LISTE", []);
          //context.commit("SET_GUTACHTEN_LISTE", response.data.data);
          return 200;
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-UAD-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        if (error.message.indexOf("code 400") !== -1) {
          //alert('Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-UAD-400)')
          return 400;
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-UAD-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-UAD-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async fetchAuftragsDetails(context, s3_url) {
      let url = context.state.api.endpoint_start_extract;
      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      let body = {
        s3_url: s3_url
      };
      try {
        const response = await axios.post(url, body, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          if (Object.keys(response.data).length > 0) {
            //Wenn Data gefüllt, wurde was erkannt. Ansonsten nicht.
            const matchesAnrede = [
              ...response.data.customer_data.name.matchAll(
                /^(Dr.|Familie|Firma|Frau|Herr|Prof.)+\s{1,}([\w_\W,.-]{1,})$/gi
              )
            ];
            let anrede = "";
            let fullN = "";

            if (matchesAnrede.length === 1 && matchesAnrede[0].length > 1) {
              anrede = matchesAnrede[0][1];
              fullN = matchesAnrede[0][2].trim();
            } else {
              fullN = response.data.customer_data.name;
            }
            const vorname = fullN.split(" ")[0];
            const nachname =
              fullN.split(" ").length > 1 ? fullN.split(" ")[1] : "";

            response.data.customer_data.anrede = anrede;
            response.data.customer_data.vorname = vorname;
            response.data.customer_data.nachname = nachname;
            delete response.data.customer_data.name;
            // response.data.customer_data.anrede = response.data.customer_data.name
            //   .split(" ")[0]
            //   .toLowerCase();
            // response.data.customer_data.name = response.data.customer_data.name.substr(
            //   response.data.customer_data.name.indexOf(" ") + 1
            // );
            context.commit("SET_KUNDEN_DETAILS", response.data.customer_data);
          }
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FAD-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FAD-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FAD-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FAD-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async fetchGutachtenListe(context, stageName) {
      let url =
        context.state.api.endpoint_deals_list + "?transition=" + stageName;
      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      try {
        // axios.interceptors.response.use(null, (error) => {
        //   console.log(JSON.stringify(error));
        //   return Promise.reject();
        // });
        const aoption = {
          method: "get",
          url: url,
          headers: header,
          validateStatus: function(status) {
            return status >= 200 && status < 300; // default
          }
        };

        const response = await axios(aoption);
        //const response = await axios.get(url, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          //Wenn Hersteller == null dann Wert einsetzen
          context.commit("SET_GUTACHTEN_LISTE", []);
          context.commit("SET_GUTACHTEN_LISTE", response.data.data);
          return response.data.data;
          //console.log('set menu LIST....')
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGL-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGL-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGL-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGL-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async persistDataIntoCurrentStage(context, params) {
      const id = params.id;
      const jsonState = params.data;
      let url = context.state.api.endpoint_deals_list + "/" + id;

      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      try {
        //alert(jsonState)
        const response = await axios.post(url, jsonState, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          // 1. reload menu-list
          context.commit("SET_GUTACHTEN_DETAILS", {});
          context.commit("SET_GUTACHTEN_DETAILS", response.data);
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDICS-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDICS-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDICS-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDICS-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async getDealInvoice(context, id) {
      let url = context.state.api.endpoint_get_post_generate_deal_invoice.replace(
        "{id}",
        id
      );

      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      try {
        //alert(jsonState)
        const response = await axios.get(url, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          // 1. reload menu-list
          //context.commit("SET_GUTACHTEN_DETAILS", {});
          //await context.dispatch("fetchGutachtenListe");
          return response.data;
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GETDI-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GETDI-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GETDI-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GETDI-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async generateDealInvoice(context, id) {
      let url = context.state.api.endpoint_get_post_generate_deal_invoice.replace(
        "{id}",
        id
      );

      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      try {
        //alert(jsonState)
        const response = await axios.post(url, {}, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          // 1. reload menu-list
          //context.commit("SET_GUTACHTEN_DETAILS", {});
          //await context.dispatch("fetchGutachtenListe");
          return response.data;
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDI-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDI-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDI-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDI-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async generateDealPZP(context, params) {
      let url = context.state.api.endpoint_get_post_generate_deal_pzp.replace(
        "{id}",
        params.id
      );
      url = url + "?invoice_id=" + params.invoiceId;

      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      try {
        //alert(jsonState)
        const response = await axios.post(url, {}, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          return response.data;
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDPZP-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        //console.log(error);
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDPZP-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDPZP-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-GDPZP-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async pushDataToNewStage(context, params) {
      const id = params.id;
      const stageName = params.stage;
      const jsonState = params.data;

      let url = context.state.api.endpoint_post_new_deal_state.replace(
        "{id}",
        id
      );
      if (params.finalize !== undefined && params.finalize === true)
        url = url + "?finalize=true";
      url = url.replace("{stage}", stageName);

      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      try {
        //alert('before request:')
        //alert(jsonState)
        const response = await axios.post(url, jsonState, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
        } else if (response.status === 200) {
          // 1. reload menu-list
          context.commit("SET_GUTACHTEN_DETAILS", {});
          //await context.dispatch("fetchGutachtenListe");
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDTNS-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        //console.log(error);
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDTNS-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDTNS-NE)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-PDTNS-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async fetchGutachtenDetails(context, id) {
      //console.log('hole: ' + id);
      let url = context.state.api.endpoint_deals_list + "/" + id;
      let tokenId = context.getters.getAuthBearer;
      let header = {
        Authorization: "Bearer " + tokenId,
        "Content-Type": "application/json"
      };
      try {
        const response = await axios.get(url, { headers: header });
        if (response.status === 401 || response.status === 403) {
          await context.dispatch("logout");
          tokenId = localStorage.getItem("id_token");
        } else if (response.status === 200) {
          //Manipulation Datum für Datepicker
          //ERSTZULASSUNG Handling

          //Datum fehlt oder von Backend mit Undefined
          //Return ez.value as empty String, damit der Picker funktioniert.
          if (
            response.data.kopfdaten.fzg_stammdaten.content.ez.value ===
              "undefined" ||
            response.data.kopfdaten.fzg_stammdaten.content.ez.value == null
          ) {
            response.data.kopfdaten.fzg_stammdaten.content.ez.value = "";
          }

          //Altes Format von Backend mit Datum gespeichert als 'DD.MM.YYYY'
          //Replace Data in Object with new Format 'YYYY-MM-DD'
          //Format Data in Frontend with this 'DD.MM.YYYY'
          if (
            response.data.kopfdaten.fzg_stammdaten.content.ez.value
              .substr(0, 3)
              .includes(".")
          ) {
            let year = response.data.kopfdaten.fzg_stammdaten.content.ez.value.substr(
              6,
              4
            );
            let month = response.data.kopfdaten.fzg_stammdaten.content.ez.value.substr(
              3,
              2
            );
            let day = response.data.kopfdaten.fzg_stammdaten.content.ez.value.substr(
              0,
              2
            );
            response.data.kopfdaten.fzg_stammdaten.content.ez.value = [
              year,
              month,
              day
            ].join("-");
            console.log(
              response.data.kopfdaten.fzg_stammdaten.content.ez.value
            );
          }

          //SCHADENTAG Handling

          //Datum fehlt oder von Backend mit Undefined
          //Return ez.value as empty String, damit der Picker funktioniert.
          if (
            response.data.kopfdaten.fzg_stammdaten.content.schadentag.value ===
              "undefined" ||
            response.data.kopfdaten.fzg_stammdaten.content.schadentag.value ==
              null
          ) {
            response.data.kopfdaten.fzg_stammdaten.content.schadentag.value =
              "";
          }

          //Altes Format von Backend mit Datum gespeichert als 'DD.MM.YYYY'
          //Replace Data in Object with new Format 'YYYY-MM-DD'
          //Format Data in Frontend with this 'DD.MM.YYYY'
          if (
            response.data.kopfdaten.fzg_stammdaten.content.schadentag.value
              .substr(0, 3)
              .includes(".")
          ) {
            let year = response.data.kopfdaten.fzg_stammdaten.content.schadentag.value.substr(
              6,
              4
            );
            let month = response.data.kopfdaten.fzg_stammdaten.content.schadentag.value.substr(
              3,
              2
            );
            let day = response.data.kopfdaten.fzg_stammdaten.content.schadentag.value.substr(
              0,
              2
            );
            response.data.kopfdaten.fzg_stammdaten.content.schadentag.value = [
              year,
              month,
              day
            ].join("-");
            console.log(
              response.data.kopfdaten.fzg_stammdaten.content.schadentag.value
            );
          }

          context.commit("SET_GUTACHTEN_DETAILS", {});
          context.commit("SET_GUTACHTEN_DETAILS", response.data);
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGD-CODE[" +
              response.status +
              "])"
          );
        }
      } catch (error) {
        console.log(error);
        if (error.message.indexOf("code 400") !== -1) {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGD-400)"
          );
        } else if (error.message === "Network Error") {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGD-EN)"
          );
        } else {
          alert(
            "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-S-FGD-UN)"
          );
        }
        await context.dispatch("logout");
      }
    },
    async redirectToAuthorization(context, auth) {
      if (auth === undefined) {
        console.log("[redirectauth] wrong call -> break");
        return false;
      }
      console.log("[redirectauth] param auth:" + auth);

      console.log(
        "[redirectauth]-initial reauthorization:" +
          JSON.stringify(window.location)
      );
      let code = undefined;

      if (
        localStorage.getItem("tempCode") !== undefined &&
        localStorage.getItem("tempCode") !== null
      ) {
        code = localStorage.getItem("tempCode");
        localStorage.removeItem("tempCode");
      }

      //2. validate code exists
      if (code === undefined || code === null) {
        alert("code LOGOUT:" + code);
        await context.dispatch("logout");
      }
      //localStorage.setItem("code",code);

      let redirectURI = context.state.api.redirect_url;
      //const currentURI = window.location.pathname;

      //console.log("[redirectauth]- 'redirectURI'-> "+redirectURI+"\n'currentURI'-> "+window.location.pathname);
      //alert('redirectURI:'+redirectURI+'\n'+'currentURI:'+window.location.pathname)

      // CODE Challenge WORKFLOW

      console.log("[redirectauth] - generate new state");
      // Create random "state"
      //let newStateToken = getRandomString();
      //localStorage.setItem("pkce_state", newStateToken);
      //localStorage.setItem("pkce_state", newStateToken);

      // Create PKCE code verifier
      let code_verifier = getRandomString();
      //localStorage.setItem("code_verifier", code_verifier);
      console.log("[redirectauth] - generate code_verifier:" + code_verifier);

      // Create code challenge
      let arrayHash = await encryptStringWithSHA256(code_verifier);
      let code_challenge = hashToBase64url(arrayHash);
      if (code_challenge !== undefined) console.log("");
      console.log("[redirectauth] - generate code_challenge:");
      //localStorage.setItem("code_challenge", code_challenge);
      // console.log("[redirectauth] - generate code_challenge:"+code_challenge);
      //
      // console.log("[redirectauth] - set riderection uri to:"+currentURI);
      //localStorage.setItem("redirect_uri", redirectURI);

      try {
        console.log("[redirectauth] - execute call");
        const loginURI =
          "https://" +
          context.state.api.domain +
          ".auth." +
          context.state.api.region +
          ".amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=" +
          context.state.api.app_client_id +
          "&code_verifier=" +
          code_verifier +
          "&code=" +
          code +
          "&scope=openid" +
          "&redirect_uri=" +
          redirectURI;
        console.log("[redirectauth] - generate code_challenge:");
        //alert('CALL token');
        const authResp = await fetch(loginURI, {
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        });
        //alert('AFTER CALL token');

        console.log("[redirectauth] check response===200 ");
        if (authResp.status !== 200) {
          alert("unknown auth-C response");
          await context.dispatch("logout");
          return;
        }
        console.log("[redirectauth] getting token ");
        const tokens = await authResp.json();
        console.log("[redirectauth]-code!=null. creaponse succeeded ");

        localStorage.setItem("access_token", tokens.access_token);
        localStorage.setItem("id_token", tokens.id_token);

        let idVerified = await verifyToken(tokens.id_token, context.state);
        if (idVerified.localeCompare("verified")) {
          alert("Invalid ID Token - " + idVerified);
          return;
        }

        const newOauth2Data = {
          token_id: tokens.id_token,
          access_token: tokens.access_token
        };

        context.commit("setOAuth2AuthorizationDetails", newOauth2Data);
        //alert('AUTH2Object was refreshed')
        await context.dispatch("loadAuthProfile");

        //router.push("/");
      } catch (err) {
        alert("ERR");
      }
    },
    async logout(context) {
      //alert('LOGOUT')
      const redirectURI = context.state.api.redirect_url;
      let stat = context.state.oauth2Object.pkce_state;
      localStorage.clear();
      const newOauth2Data = {
        token_id: undefined,
        access_token: undefined
      };
      context.commit("setOAuth2AuthorizationDetails", newOauth2Data);

      //stat = getRandomString();
      //localStorage.setItem("pkce_state", stat);
      stat = "ug24initialState";
      //localStorage.setItem("pkce_state", stat);

      //localStorage.removeItem("code_verifier");
      //localStorage.removeItem("code_challenge");

      // Create PKCE code verifier
      // let code_verifier = getRandomString();
      // localStorage.setItem("code_verifier", code_verifier);

      // Create code challenge
      // let arrayHash = await encryptStringWithSHA256(code_verifier);
      // let code_challenge = hashToBase64url(arrayHash);
      // localStorage.setItem("code_challenge", code_challenge);

      location.href =
        "https://" +
        context.state.api.domain +
        ".auth." +
        context.state.api.region +
        ".amazoncognito.com/logout?response_type=code&state=" +
        stat +
        "&client_id=" +
        context.state.api.app_client_id +
        "&scope=openid&redirect_uri=" +
        redirectURI;
    },
    async loadAuthProfile(context) {
      const token_id = context.getters.getAuthBearer;
      const bresponse = await fetch(context.state.api.endpoint_me, {
        method: "get",
        headers: {
          authorization: "Bearer " + token_id
        }
      });
      //alert('vbbbbb:'+JSON.stringify(bresponse))
      if (bresponse.status === 401 || bresponse.status === 403) {
        console.log("[loadAuthProfile]: response 401");
        await context.dispatch("logout");
      }
      const userObject = await bresponse.json();
      //alert('userObject loaded:'+JSON.stringify(userObject))
      context.commit("setUserObject", userObject);
    },
    async initTextBlockTemplates(context) {
      if (context.getters.getTextBlockTemplates.length === 0) {
        //Textbausteine
        const respData = await TextBausteineService.getTextbausteineJson(
          context,
          context.getters.getAPIContext.endpoint_get_text_block_templates
        );
        context.commit("SET_TEXTBLOCK_TEMPLATES", respData);

        //Categories
        let blocks = this.getters.getTextBlockTemplates;
        let i = 0;
        let len = blocks.length;
        let categories = [];
        while (i < len) {
          if (categories.indexOf(blocks[i].title) === -1) {
            categories.push(blocks[i].title);
          }
          i++;
        }
        categories.sort();

        context.commit("SET_TEXTBLOCK_CATEGORIES", categories);
      }
      return true;
    },
    async pushNewTextBlockTemplate(context, payload) {
      const respData = await TextBausteineService.pushNewTextbaustein(
        context,
        context.getters.getAPIContext.endpoint_put_new_text_block,
        payload
      );
      if (respData !== undefined)
        context.commit("SET_TEXTBLOCK_TEMPLATES", respData);
      return true;
    },
    async updateTextBlockTemplate(context, payload) {
      const respData = await TextBausteineService.updateTextbaustein(
        context,
        context.getters.getAPIContext.endpoint_put_new_text_block,
        payload
      );
      if (respData !== undefined)
        context.commit("SET_TEXTBLOCK_TEMPLATES", respData);
      return true;
    },

    //Berechnung Gesamtkalkulation
    gesamtkalkulation(context) {
      let gesamtkalkulation = {},
        indizes = [1, 2, 4];
      for (let minderwert_item of this.state.minderwerte) {
        for (let row of minderwert_item.rows) {
          for (var j of indizes) {
            if (gesamtkalkulation[j] == undefined) {
              console.log(JSON.stringify(minderwert_item.headers[j]["text"]));
              gesamtkalkulation[j] = {
                key: minderwert_item.headers[j]["text"],
                sum: parseNum(row[`header${j}`])
              };
            } else {
              gesamtkalkulation[j]["sum"] += parseNum(row[`header${j}`]);
            }
          }
        }
      }

      console.log("array");
      console.log(JSON.stringify(gesamtkalkulation));

      gesamtkalkulation[5] = {
        key: "Ersparnis",
        sum: formatNum(
          gesamtkalkulation[2]["sum"] - gesamtkalkulation[4]["sum"]
        )
      };
      for (j of indizes) {
        gesamtkalkulation[j]["sum"] = formatNum(gesamtkalkulation[j]["sum"]);
      }

      context.commit("SET_GUTACHTEN_GESAMTKALKULATION", gesamtkalkulation);
    }
    // async reloadState(context) {
    //   //alert('reload state:'+context)
    //   if (Object.keys(context.state.userobject).length === 0) {
    //     context.commit(
    //       "setUserObject",
    //       localStorage.getItem("userobject") !== null
    //         ? JSON.parse(localStorage.getItem("userobject"))
    //         : {}
    //     );
    //   }
    //   if (context.state.oauth2Object.token_id === undefined) {
    //     context.commit(
    //       "setOAuth2AuthorizationDetails",
    //       localStorage.getItem("oauth2Object") !== null
    //         ? JSON.parse(localStorage.getItem("oauth2Object"))
    //         : {}
    //     );
    //   }
    //   //alert('reload state ende')
    // },

    // async checkAuthDataExist(context) {
    //   if (
    //     context.state.oauth2Object.token_id === undefined &&
    //     (localStorage.getItem("oauth2Object") === null ||
    //       localStorage.getItem("oauth2Object") === "{}")
    //   ) {
    //     await context.dispatch("redirectToAuthorization");
    //     //alert('leeerreer')
    //   }
    // },

    // clearAuthObjectState(context){
    //   const currentAuthObject=context.state.oauth2Object;
    //   const newOauth2Data = {
    //     token_id: undefined,
    //    // code: undefined,
    //     access_token: undefined,
    //     refresh_token: currentAuthObject.refresh_token,
    //    // code_verifier: undefined,
    //    // code_challenge: undefined,
    //    // pkce_state: undefined
    //   };
    //
    //   context.commit("setOAuth2AuthorizationDetails", newOauth2Data);
    //
    //   localStorage.removeItem('token_id');
    //   localStorage.removeItem('access_token');
    //   alert('[clearAuthObjectState] invalidate Oauth2Dataobject');
    // },
  },

  modules: {}
});

//Convert Payload from Base64-URL to JSON
const decodePayload = payload => {
  const cleanedPayload = payload.replace(/-/g, "+").replace(/_/g, "/");
  const decodedPayload = atob(cleanedPayload);
  const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
    const uriEncodedChar = ("00" + char.charCodeAt(0).toString(16)).slice(-2);
    return `${acc}%${uriEncodedChar}`;
  }, "");
  const jsonPayload = decodeURIComponent(uriEncodedPayload);

  return JSON.parse(jsonPayload);
};

//Parse JWT Payload
const parseJWTPayload = token => {
  //const [header, payload, signature] = token.split('.');
  const payload = token.split(".")[1];
  const jsonPayload = decodePayload(payload);

  return jsonPayload;
};

//Parse JWT Header
const parseJWTHeader = token => {
  //const [header, payload, signature] = token.split('.');
  const header = token.split(".")[0];
  const jsonHeader = decodePayload(header);

  return jsonHeader;
};

//Generate a Random String
const getRandomString = () => {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  const binaryStringItems = randomItems.map(
    dec => `0${dec.toString(16).substr(-2)}`
  );
  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, "");
};

//Encrypt a String with SHA256
const encryptStringWithSHA256 = async str => {
  const PROTOCOL = "SHA-256";
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(str);
  return window.crypto.subtle.digest(PROTOCOL, encodedData);
};

//Convert Hash to Base64-URL
const hashToBase64url = arrayBuffer => {
  const items = new Uint8Array(arrayBuffer);
  const stringifiedArrayHash = items.reduce(
    (acc, i) => `${acc}${String.fromCharCode(i)}`,
    ""
  );
  const decodedHash = btoa(stringifiedArrayHash);

  const base64URL = decodedHash
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
  return base64URL;
};

// function refineURL() {
//   console.log('<<refine URL>>');
//   //alert('target-URL:'+window.location.href+'\n'+'correct-URL:'+window.location.origin+window.location.pathname+window.location.hash);
//   console.log(JSON.stringify(window.location));
//   //get full URL
//   let currURL = window.location.href; //get current address
//   console.log('current window.location.href:'+currURL);
//
//   //clear hash
//
//   let hasHash = true;
//   if (currURL.substring(currURL.length - 2) !== "#/") hasHash = false;
//   console.log('has HASH? '+hasHash);
//
//   if (hasHash) {
//     currURL = currURL.substring(0, currURL.length - 2);
//     console.log('changed currURL to: '+currURL);
//   }
//
//
//   //Get the URL between what's after '/' and befor '?'
//   //1- get URL after'/'
//   let afterDomain = currURL.substring(currURL.lastIndexOf("/") + 1);
//   //2- get the part before '?'
//   let beforeQueryString = afterDomain.split("?")[0];
//
//   if (hasHash) beforeQueryString = beforeQueryString + "/" + "#/";
//   ////let beforeQueryString= window.location.origin+window.location.pathname+window.location.hash
//   if(!hasHash && beforeQueryString.indexOf('#/')===-1) beforeQueryString = "/";
//   //alert('link return:'+beforeQueryString)
//   return beforeQueryString;
// }

//verify token
async function verifyToken(token, state) {
  //get Cognito keys
  let keys;
  let keys_url =
    "https://cognito-idp." +
    state.api.region +
    ".amazonaws.com/" +
    state.api.user_pool_id +
    "/.well-known/jwks.json";
  await fetch(keys_url)
    .then(response => {
      return response.json();
    })
    .then(data => {
      keys = data["keys"];
    });

  //Get the kid (key id)
  let tokenHeader = parseJWTHeader(token);
  let key_id = tokenHeader.kid;

  //search for the kid key id in the Cognito Keys
  const key = keys.find(key => key.kid === key_id);
  if (key === undefined) {
    return "Public key not found in Cognito jwks.json";
  }

  //verify JWT Signature
  let keyObj = KEYUTIL.getKey(key);
  let isValid = KJUR.jws.JWS.verifyJWT(token, keyObj, { alg: ["RS256"] });
  if (!isValid) return "Signature verification failed";

  //verify token has not expired
  let tokenPayload = parseJWTPayload(token);
  if (Date.now() >= tokenPayload.exp * 1000) {
    return "Token expired";
  }

  //verify app_client_id
  let n = tokenPayload.aud.localeCompare(state.api.app_client_id);
  if (n != 0) {
    return "Token was not issued for this audience";
  }
  return "verified";
}
