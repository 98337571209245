<template>
  <!--  <Uebersicht></Uebersicht>-->
  <v-container fill-height class="mt-0 pt-0">
    <div>
      <h3>
        {{ text }}
      </h3>
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import Uebersicht from "@/components/Uebersicht";

export default {
  name: "Home",
  data: function() {
    return {
      text: ""
    };
  },

  async mounted() {
    this.$store.commit("setMainContextName", "Übersicht");

    if (
      this.isReportsRoute() &&
      this.$store.getters.getUserPermissions.tabs.length >= 1
    ) {
      const lastActiveTabRoute = this.$store.getters.getLastActiveTabRoute;
      await this.$router.push(lastActiveTabRoute);
    } else {
      this.loadNonPermissionsText();
    }
  },
  methods: {
    loadNonPermissionsText: function() {
      setTimeout(
        function() {
          this.showText();
        }.bind(this),
        1000
      );
    },
    showText: function() {
      this.text =
        "Sie besitzen nicht ausreichend Berechtigungen, um die Gutachten anzeigen bzw. bearbeiten zu können";
    }
  }
};
</script>
