import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   redirect: '/home'
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: async (to, from, next) => {
      //alert('[router - home]:'+JSON.stringify(window.location));
      if (window.location.search.indexOf("?code=") !== -1) {
        if (from.name === "xxcdgswe923fue") console.log("");
        // persist temporary new 'code' and 'state' to localStorage. Method 'redirectToAuthorization' will revalidate these vars
        let urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");
        localStorage.setItem("tempCode", code);
        //alert('SEARCH-CODE:'+urlParams.get("code"));
        console.log("[router] home  code:" + localStorage.getItem("tempCode"));
        window.history.replaceState(
          {},
          document.title,
          window.location.origin +
            window.location.pathname +
            window.location.hash
        );
        await store.dispatch("redirectToAuthorization", "auth");
        //alert('DISPATCHCHC')
      }
      if (to.name !== "dummyxxxxxxxxxxxxxxx") next();
    }
  },
  {
    path: "/neuer-auftrag",
    name: "CreateNew",
    component: () => import("../views/CreateNew.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Account.vue")
  },
  // {
  //   path: "/overview-acceptance",
  //   name: "overview-acceptance",
  //   component: () => import("../views/OverviewAcceptance.vue"),
  //   beforeEnter: (to, from, next) => {
  //     //alert('overview-acceptance-target:'+window.location.origin+window.location.pathname+window.location.hash)
  //     //alert('overview-acceptance-curloc:'+window.location.href)
  //     //alert('overview-acceptance:\n'+JSON.stringify(Object.keys(to))+'\n\n\n'+JSON.stringify(Object.keys(from))+'\nto-name:'+to.name+'\nto-path:'+to.path+'\nto-query:'+JSON.stringify(to.query)+'\nto-params:'+JSON.stringify(to.params)+'\nto-fullPath:'+JSON.stringify(to.fullPath)+'\nto-meta:'+JSON.stringify(to.meta));
  //     if(window.location.origin+window.location.pathname+window.location.hash!==window.location.href){
  //       window.history.replaceState({}, document.title, window.location.origin+window.location.pathname+window.location.hash);
  //     }
  //     if (to.name !== 'dummyxxxxxxxxxxxxxxx' ) next()
  //   }
  // },
  {
    path: "/overview-qs-tech",
    name: "overview-qs-tech",
    component: () => import("../views/OverviewQSTech.vue")
  },
  // {
  //   path: "/overview-qs-fach",
  //   name: "overview-qs-fach",
  //   component: () => import("../views/OverviewQSFach.vue")
  // },
  {
    path: "/overview-bearbeitung",
    name: "overview-bearbeitung",
    component: () => import("../views/OverviewBearbeitung.vue")
  },
  {
    path: "/overview-review",
    name: "overview-review",
    component: () => import("../views/OverviewReview.vue")
  },
  {
    path: "/overview-done",
    name: "overview-done",
    component: () => import("../views/OverviewDone.vue")
    // beforeEnter: (to, from, next) => {
    //   if(window.location.origin+window.location.pathname+window.location.hash!==window.location.href){
    //     window.history.replaceState({}, document.title, window.location.origin+window.location.pathname+window.location.hash);
    //   }
    //   store.commit("setLastActiveTabRoute", 'overview-done');
    //   if (to.name !== 'dummyxxxxxxxxxxxxxxx' ) next()
    // }
  }
];

const router = new VueRouter({
  //mode: 'history',
  routes
});
// router.beforeEach(async (to, from, next) => {
//   if(window.location.search.indexOf('?code=')===-1){
//     if(store.getters..oauth2Object === undefined && store.state.oauth2Object.token_id.token_id)
//   }
//   return next();
// });
router.afterEach(async (to, from, next) => {
  let isReportRoute = false;
  console.log("PATH-TO:" + to.path);
  const permittedRoutes = store.getters.getUserPermissions.routes;
  switch (to.path) {
    case "/":
      isReportRoute = true;
      if (permittedRoutes.includes("/overview-qs-tech"))
        store.commit("setLastActiveTabRoute", "overview-qs-tech");
      else if (permittedRoutes.includes("/overview-bearbeitung"))
        store.commit("setLastActiveTabRoute", "overview-bearbeitung");
      else if (permittedRoutes.includes("/neuer-auftrag")) {
        store.commit("setLastActiveTabRoute", "neuer-auftrag");
        isReportRoute = false;
      } else {
        store.commit("setLastActiveTabRoute", "account");
        isReportRoute = false;
      }
      //next({path:'/overview-qs-tech'})
      isReportRoute = true;
      break;
    case "/neuer-auftrag":
      store.commit("setLastActiveTabRoute", "neuer-auftrag");
      //next({path:to.path})
      break;
    case "/about":
      store.commit("setLastActiveTabRoute", "about");
      //next({path:to.path})
      break;
    case "/account":
      store.commit("setLastActiveTabRoute", "account");
      //next({path:to.path})
      break;
    case "/overview-qs-tech":
      store.commit("setLastActiveTabRoute", "overview-qs-tech");
      //next({path:to.path})
      isReportRoute = true;
      break;
    case "/overview-bearbeitung":
      store.commit("setLastActiveTabRoute", "overview-bearbeitung");
      //next({path:to.path})
      isReportRoute = true;
      break;
    case "/overview-review":
      store.commit("setLastActiveTabRoute", "overview-review");
      //next({path:to.path})
      isReportRoute = true;
      break;
    case "/overview-done":
      store.commit("setLastActiveTabRoute", "overview-done");
      //next({path:to.path})
      isReportRoute = true;
      break;
    default:
      alert("Unknown Route:" + to.path);
      await store.dispatch("logout");
      break;
  }
  if (to.path === "-1") next();

  //alert("BEFORE all:"+JSON.stringify(store.getters.getUserPermissions.routes));
  if (store.getters.getUserPermissions.routes.length === 0) {
    await store.dispatch("loadAuthProfile");
  }
  //alert("BEFORE all 2:"+JSON.stringify(store.getters.getUserPermissions.routes));

  //alert(store.getters.getUserPermissions.tabs.length)

  if (isReportRoute && store.getters.getUserPermissions.tabs.length === 0) {
    //user has no permissions to see workflow-tabs
    //redirecting to account
    if (permittedRoutes.includes("/neuer-auftrag")) {
      await this.$router.push("/neuer-auftrag");
      return next({ path: "/neuer-auftrag" });
    } else {
      //await this.$router.push("/account");
      return next({ path: "/account" });
    }
  } else if (
    !permittedRoutes.includes("/neuer-auftrag") &&
    to.path === "/neuer-auftrag"
  ) {
    return next({ path: "/account" });
  }

  //
  // if (to.matched.some(route => route.meta.requiresAuth)) {
  //   store.state.authStatus.then(loggedIn => {
  //     if (loggedIn) next();
  //     else next({ name: "home" });
  //   });
  // } else {
  //   next();
  // }
});

// const setTimeoutPromise = timeout => new Promise(resolve => {
//   setTimeout(resolve, timeout);
// });

export default router;

//prüfen wenn /route neugeladen wird dann sind tokens nicht da. Probieren von local store zu laden und dann logout wenn was
