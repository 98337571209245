export const myVar = 'This is my variable _'
export const backendApiVersion = 'v1'
export const backendBasePath = (process.env.NODE_ENV==='production')?'https://z10tq4xkj3.execute-api.eu-west-1.amazonaws.com/dev':'https://z10tq4xkj3.execute-api.eu-west-1.amazonaws.com/dev';
export const globals = {
    some: 'Settings',
    env: (process.env.NODE_ENV==='production')?'prod':'dev',
    api_endpoint_me: backendBasePath+'/'+backendApiVersion+'/me',
    api_endpoint_deals_list: backendBasePath+'/'+backendApiVersion+'/deals',
    api_endpoint_edited_push: backendBasePath+'/'+backendApiVersion+'/scans/edited/push',
    api_endpoint_start_extract: backendBasePath+'/'+backendApiVersion+'/workflows/extract-customer-data',
    api_endpoint_submit_new_deal: backendBasePath+'/'+backendApiVersion+'/workflows/create-new-deal',
    api_endpoint_url_signer: backendBasePath+'/'+backendApiVersion+'/utils/url-signer',
    api_endpoint_get_signed_download_url: backendBasePath+'/'+backendApiVersion+'/utils/get-signed-url',
    api_endpoint_get_text_block_templates: backendBasePath+'/'+backendApiVersion+'/utils/text-block-templates',
    api_endpoint_put_new_text_block: backendBasePath+'/'+backendApiVersion+'/utils/text-block-templates',
    api_endpoint_post_new_deal_state: backendBasePath+'/'+backendApiVersion+'/deals/{id}/transition-to/{stage}',
    api_endpoint_get_post_generate_deal_invoice: backendBasePath+'/'+backendApiVersion+'/deals/{id}/invoice',
    api_endpoint_get_post_generate_deal_pzp: backendBasePath+'/'+backendApiVersion+'/deals/{id}/pzp',

    domain: (process.env.NODE_ENV==='production')?'ug-up-dom-eucentral1-dev':'ug-up-dom-eucentral1-dev',
    region: (process.env.NODE_ENV==='production')?'eu-central-1':'eu-central-1',
    appClientId: (process.env.NODE_ENV==='production')?'11i8c1jmr4epqpg5feqteo0ago':'11i8c1jmr4epqpg5feqteo0ago',
    userPoolId: (process.env.NODE_ENV==='production')?'eu-central-1_ZKIjHEuuh':'eu-central-1_ZKIjHEuuh',
    redirectURI: (process.env.NODE_ENV==='production')?'https://portal.madaki-dev.de':'https://localhost:8080'

}








