<template>
  <v-navigation-drawer v-model="drawer" fixed temporary>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          Menü
        </v-list-item-title>
        <v-list-item-subtitle>
          UG-24 Portal
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group
        v-model="group"
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item
          v-for="(item, index) in getMainMenu"
          :key="index"
          :to="item.to"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
        <!--        <v-list-item to="/">-->
        <!--          <v-list-item-icon>-->
        <!--            <v-icon>mdi-home</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Übersicht</v-list-item-title>-->
        <!--        </v-list-item>-->

        <!--        <v-list-item to="/neuer-auftrag">-->
        <!--          <v-list-item-icon>-->
        <!--            <v-icon>mdi-file-plus</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Neu anlegen</v-list-item-title>-->
        <!--        </v-list-item>-->

        <!--        <v-list-item to="/about">-->
        <!--          <v-list-item-icon>-->
        <!--            <v-icon>mdi-help</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Hilfe</v-list-item-title>-->
        <!--        </v-list-item>-->

        <!--        <v-list-item to="/account">-->
        <!--          <v-list-item-icon>-->
        <!--            <v-icon>mdi-account</v-icon>-->
        <!--          </v-list-item-icon>-->
        <!--          <v-list-item-title>Account</v-list-item-title>-->
        <!--        </v-list-item>-->
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      group: null
    };
  },
  computed: {
    getMainMenu: function() {
      return this.$store.getters.getUserPermissions.menu;
    }
  }
};
</script>
