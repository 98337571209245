import axios from "axios";

export default {
  async pushNewTextbaustein(context, tUrl, payload) {
    let tokenId = context.getters.getAuthBearer;
    //alert(JSON.stringify(payload));
    const header = {
      Authorization: "Bearer " + tokenId,
      "Content-Type": "application/json"
    };
    try {
      const response = await axios.put(tUrl, payload, { headers: header });
      if (response.status === 401 || response.status === 403) {
        await context.dispatch("logout");
      } else if (response.status === 200) {
        return response.data;
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-PNT-CODE[" +
            response.status +
            "])"
        );
      }
    } catch (error) {
      if (error.message.indexOf("code 400") !== -1) {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-PNT-400)"
        );
      } else if (error.message === "Network Error") {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-PNT-NE)"
        );
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-PNT-UN)"
        );
      }
      await context.dispatch("logout");
    }
  },
  async updateTextbaustein(context, tUrl, payload) {
    let tokenId = context.getters.getAuthBearer;
    //alert(JSON.stringify(payload));
    const header = {
      Authorization: "Bearer " + tokenId,
      "Content-Type": "application/json"
    };
    try {
      const response = await axios.post(tUrl, payload, { headers: header });
      if (response.status === 401 || response.status === 403) {
        await context.dispatch("logout");
      } else if (response.status === 200) {
        return response.data;
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-UT-CODE[" +
            response.status +
            "])"
        );
      }
    } catch (error) {
      if (error.message.indexOf("code 400") !== -1) {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-UT-400)"
        );
      } else if (error.message === "Network Error") {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-UT-NE)"
        );
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-UT-UN)"
        );
      }
      await context.dispatch("logout");
    }
  },
  async getTextbausteineJson(context, tUrl) {
    let tokenId = context.getters.getAuthBearer;
    let header = {
      Authorization: "Bearer " + tokenId,
      "Content-Type": "application/json"
    };
    try {
      const response = await axios.get(tUrl, { headers: header });
      if (response.status === 401 || response.status === 403) {
        await context.dispatch("logout");
      } else if (response.status === 200) {
        return response.data;
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-GTJ-CODE[" +
            response.status +
            "])"
        );
      }
    } catch (error) {
      if (error.message.indexOf("code 400") !== -1) {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-GTJ-400)"
        );
      } else if (error.message === "Network Error") {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-GTJ-NE)"
        );
      } else {
        alert(
          "Bei Kommunikation mit dem Server ist ein Fehler aufretten(code: JS-TBS-GTJ-UN)"
        );
      }
      await context.dispatch("logout");
    }
  }
};
