// import Vue from "vue";
// import Vuetify from "vuetify/lib";
//
// Vue.use(Vuetify);
//
// export default new Vuetify({});
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {};

export default new Vuetify(opts);
