<template>
  <v-app id="inspire">
    <v-card class="overflow-hidden fill-height">
      <v-app-bar app dense color="primary" dark>
        <v-app-bar-nav-icon
          @click="$refs.drawer.drawer = !$refs.drawer.drawer"
        ></v-app-bar-nav-icon>

        <v-toolbar-title>{{ mainContextName }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <span style="padding-right: 1em">{{ $store.getters.getUserName }}</span>
        <v-avatar v-bind:style="colorObject" size="32">{{
          $store.getters.getUserName.substring(0, 2)
        }}</v-avatar>
        <v-btn v-on:click="logout" text>
          Logout
        </v-btn>
        <template v-slot:extension>
          <v-tabs
            align-with-title
            v-if="isReportsRoute()"
            v-model="active_tab"
            @change="executeChange"
          >
            <v-tab
              v-show="$store.getters.getUserPermissions.tabs.length >= 1"
              v-for="i in $store.getters.getUserPermissions.tabs"
              :key="i.id"
              :tabindex="i.id"
              :href="`#tab-${i.id}`"
              :to="i.route"
            >
              {{ i.text }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>

      <navigation-drawer app ref="drawer"></navigation-drawer>

      <v-main class="grey lighten-3 fill-height mt-0" fluid>
        <router-view></router-view>
      </v-main>
      <v-footer app absolute padless class="font-weight-medium elevation-9">
        <v-col class="text-center" cols="12">
          {{ new Date().getFullYear() }} —
          <strong>UG24 Schadenmanagement</strong>
        </v-col>
      </v-footer>
    </v-card>
  </v-app>
</template>

<script>
import navigationDrawer from "./components/NavigationDrawer";
import Vue from "vue";

Vue.mixin({
  methods: {
    isReportsRoute: function() {
      if (
        this.$route.name !== null &&
        (this.$route.name.toLowerCase() === "home" ||
          this.$route.name.toLowerCase() === "overview-qs-tech" ||
          //this.$route.name.toLowerCase() === "overview-acceptance" ||
          this.$route.name.toLowerCase() === "overview-bearbeitung" ||
          this.$route.name.toLowerCase() === "overview-review" ||
          this.$route.name.toLowerCase() === "overview-done")
      )
        return true;
      else return false;
    }
  }
});

export default {
  name: "App",

  components: {
    navigationDrawer
  },

  data: () => ({
    active_tab: "/"
  }),
  async mounted() {
    // let recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute(
    //         "src",
    //         "https://www.google.com/recaptcha/api.js"
    // );
    // document.head.appendChild(recaptchaScript);
    //
    // await this.$store.dispatch("checkAuthDataExist")
    // await this.$store.dispatch("reloadState");
    //
    // //console.log("isReportRoute?"+this.isReportsRoute())
    // //console.log("allowed tabs?"+this.$store.getters.getUserPermissions.tabs.length)
    //
    // if (
    //         (this.isReportsRoute() || this.$route.name===null) &&
    //         this.$store.getters.getUserPermissions.tabs.length >= 1
    // ) {
    //   const lastActiveTabRoute = this.$store.getters.getLastActiveTabRoute;
    //   await this.$router.push(lastActiveTabRoute);
    // }else{
    //   //user has no permissions to see workflow-tabs
    //   //redirecting to account
    //   const permittedRoutes=this.$store.getters.getUserPermissions.routes;
    //   if(permittedRoutes.includes("/neuer-auftrag")){
    //     await this.$router.push("/neuer-auftrag");
    //   }else{
    //     await this.$router.push("/account");
    //   }
    // }
  },
  computed: {
    mainContextName() {
      return this.$store.state.main_context_name;
    },
    colorObject: function() {
      return {
        "background-color":
          this.$store.getters.getUserName === "" ? "white" : "lightgreen",
        "font-weight": "bold",
        color: "black"
      };
    }
  },
  watch: {
    "$store.state.main_context_name": function() {
      console.log(this.$store.state.main_context_name);
    },
    mainContextName(newCount, oldCount) {
      // Our fancy notification (2).
      if (newCount === "xxxxxx" || oldCount === "xxxx") console.log(``);
    }
  },
  methods: {
    executeChange: function(e) {
      this.$store.commit("setLastActiveTabRoute", e);
    },
    async logout() {
      await this.$store.dispatch("logout");
    }
  }
};
</script>
<style>
header {
  max-height: 82px;
}
header .v-toolbar__extension {
  max-height: 32px;
}
header .v-toolbar__extension .v-tabs-bar {
  max-height: 38px;
}
/*main{*/
/*  height:100%;*/
/*}*/
</style>
